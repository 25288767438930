<template lang="pug">
.vc-order-cart-item(
  v-if="isVariantLoaded"
  :class="{ disabled: isConfirmed }"
)
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )

  .part.product-info
    .remove-button(
      role="button"
      @click="removeCartItem"
    )
      .icon
        i.fa.fa-close
    .index-indicator
      .icon {{ index + 1 }}
    a.picture-wrapper(:href="productPath")
      img.picture(:src="cartItem.coverImageThumb()")
    .info-wrapper
      a.product-name(:href="productPath")
        span.allow-backorder-tag(v-if="variant.is_allow_backorder") {{ attributeLocaleText('product/variant', 'is_allow_backorder') }}
        | {{ variant.product_name }}

      b-dropdown(
        v-model="form.variant_id"
        :disabled="isConfirmed || cartItem.isAddOnItem || variant.is_bundle"
        @change="variantChangedHandler"
        :class="{ 'has-message': variant.is_allow_backorder || selectableVariants.some((variant) => variant.stock < 1) }"
      )
        .variants-dropdown(
          v-if="variant.has_more_variants"
          role="button"
          @click="fetchProductInfo"
          slot="trigger"
        )
          .variant-name {{ variant.displayName() }}
          .clickable-indicator
            .icon
              i.fa.fa-chevron-down

        b-loading(
          :active.sync="isProductLoading"
          :is-full-page="false"
        )

        b-dropdown-item(
          v-for="variant in selectableVariants"
          :key="variant.id"
          :disabled="variant.stock < 1 || alreadyExists(variant)"
          :value="variant.id"
        )
          .variant-name
            .message
              span.sold-out(
                v-if="variant.stock < 1 && !variant.is_allow_backorder"
              ) {{ copyLocaleText(`stock_status.${variant.stock_status}`) }}
            span.allow-backorder(v-if="variant.is_allow_backorder") {{ attributeLocaleText('product/variant', 'is_allow_backorder') }}
          span.name {{ variant.name }}
          .icon
            i.fa.fa-circle-o
      a.collect-product(
        @click="collectButtonClickedHandler"
        :class="{ '-is-collected': isCollected }"
      )
        .icon
          i.fa(:class="collectButtonIcon")
        span {{ collectButtonText.long }}
  .part.quantity-controller
    .unit-price
      span.label {{ attributeLocaleText('order/item', 'price') }}
      span.price(data-currency="TWD") {{ toMoney(cartItem.price, { isExchange: false }).format() }}
      span.exchanged-price(v-if="currentCurrency !== 'TWD'")
        span.price(:data-currency="currentCurrency") {{ toMoney(cartItem.price).format() }}
    quantity-picker(
      :quantity.sync="form.quantity"
      :current-stock="variant.stock"
      :current-variant="variant"
      @update:quantity="quantityUpdatedHandler"
      :is-confirmed="isConfirmed || cartItem.isAddOnItem"
      :is-allow-backorder="variant.is_allow_backorder"
    )
  .part.price-info(:class="useType")
    .sub-total
      span.label {{ attributeLocaleText('order/item', 'sub_total') }}
      span.price(data-currency="TWD") {{ twdSubTotal.format() }}
      span.exchanged-price(v-if="currentCurrency !== 'TWD'")
        span.price(:data-currency="currentCurrency") {{ subTotal.format() }}
      span.gift-tag(v-if="useType === 'gift'") {{ copyLocaleText('gift') }}
      span.add-on-tag(v-if="cartItem.isAddOnItem") {{ copyLocaleText('add_on_item') }}
</template>

<script>
import debounce from 'lodash.debounce'
import productCollectMixin from '../mixins/product_collect_mixin'
import Form from 'odd-form_object'
import QuantityPicker from '../product/quantity-picker.vue'
import generateGA4Events from '@services/generate_ga4_events'

export default {
  components: {
    QuantityPicker
  },

  mixins: [productCollectMixin],

  props: {
    index: {
      type: Number,
      required: true
    },

    cartItem: {
      type: Object,
      required: true
    },

    cartService: {
      type: Object,
      required: true
    },

    isConfirmed: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      }
    },

    cartItems: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      form: new Form(this.cartItem),
      isLoading: false
    }
  },

  computed: {
    collectableProductId() {
      return String(this.variant.product_id)
    },

    variant() {
      return this.$store.getters['productVariants/find'](this.form.variant_id)
    },

    selectableVariants() {
      return this.$store.getters['productVariants/all']
        .filter((variant) => variant.product_id === this.variant.product_id)
        .sort((a, b) => a.id - b.id)
    },

    isProductLoading() {
      return this.$store.getters['products/isLoading']
    },

    isVariantLoaded() {
      return typeof this.variant.id === 'string'
    },

    subTotal() {
      return this.toMoney(this.cartItem.price).multiply(this.form.quantity)
    },

    twdSubTotal() {
      return this.toMoney(this.cartItem.price, { isExchange: false }).multiply(
        this.form.quantity
      )
    },

    /**
     * @returns {string}
     */
    useType() {
      return this.cartItem.use_type
    },

    productPath() {
      return `products/${this.variant.product_id}`
    }
  },

  watch: {
    cartItem: {
      handler(newValue) {
        this.form = new Form(newValue)
      }
    }
  },
  // created() {},
  // mounted() {},

  methods: {
    quantityUpdatedHandler: debounce(function (quantity) {
      this.isLoading = true
      this.form.quantity = quantity

      this.cartService
        .updateCartItem(this.form.sync())
        .then(() => {
          this.form = new Form(this.cartItem)
          this.isLoading = false
        })
        .catch((errors) => {
          this.isLoading = false

          if (errors.response.status !== 422) return
          if (errors.response.data.code === 'min_purchase_quantity_failure')
            this.form.quantity = this.variant.min_purchase_quantity
          if (errors.response.data.code === 'max_purchase_quantity_failure')
            this.form.quantity = this.variant.max_purchase_quantity

          if (errors.response.data.validation_errors) {
            this.$store.dispatch('addFlashMessage', [
              'notice',
              Object.values(errors.response.data.validation_errors)[0].join(
                ', '
              )
            ])
          }
        })
    }, 500),

    removeCartItem() {
      return this.cartService
        .removeCartItem(this.cartItem)
        .then((_) => {
          return this._sendGaRemoveFromCartEvent()
        })
        .then((_) => {
          return this.$store.dispatch('ga4Operation', [
            generateGA4Events('remove_from_cart', {
              item: this.variant,
              quantity: this.cartItem.quantity
            }),
            [
              'event',
              'td_remove_from_cart',
              { td_position: this.variant.product_name }
            ]
          ])
        })
        .then((_) => {
          return this.$store.dispatch('addFlashMessage', [
            'success',
            this.messageLocaleText('cart_item_removed_successfully')
          ])
        })
    },

    variantChangedHandler(value) {
      if (this.isConfirmed) return

      this.isLoading = true
      this.form.variant_id = value
      this.form.quantity = this.variant.min_purchase_quantity

      this.cartService
        .updateCartItem(this.form.sync())
        .then(() => {
          this.form = new Form(this.cartItem)
          this.isLoading = false
        })
        .catch((errors) => {
          if (
            errors.response &&
            errors.response.status === 422 &&
            errors.response.data.code === 'validation_failure'
          ) {
            this.isLoading = false
            this.form.variant_id = this.cartItem.variant.id
            return this.$store.dispatch('addFlashMessage', [
              'notice',
              Object.values(errors.response.data.validation_errors)[0][0]
            ])
          }
        })
    },

    fetchProductInfo() {
      this.$store.dispatch('products/find', this.variant.product_id)
    },

    _sendGaRemoveFromCartEvent() {
      return this.$store.dispatch('gaOperation', [
        [
          'ec:addProduct',
          {
            id: this.variant.sku,
            name: this.variant.product_name,
            variant: this.variant.name,
            price: this.toMoney(this.variant.consumer_price, {
              isExchange: false
            }).amount,
            quantity: this.cartItem.quantity
          }
        ],
        ['ec:setAction', 'remove'],
        [
          'send',
          {
            hitType: 'event',
            eventCategory: 'Ecommerce',
            eventAction: 'Remove from Cart'
          }
        ]
      ])
    },

    alreadyExists(variant) {
      return !!this.cartItems
        .filter((item) => item.variant_id !== this.cartItem.variant_id)
        .find((item) => variant.id === item.variant_id)
    }
  }
}
</script>
